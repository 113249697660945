import TestPage from "@/modules/test/TestPage.vue";
import { createRouter, createWebHistory } from "vue-router";
import AuthRoutes from "./modules/auth/authRoutes.js";
import AppRoutes from "./modules/main/appRoutes.js";
import SandboxRoutes from "./modules/sandbox/routes.js";
import DefaultRoutes from "./routes/defaultRoutes.js";
import BlankPage from "./routes/BlankPage.vue";
import { sleep } from "@/helpers/helpers";
import store from "@/store";

// When out of memory, use this > BASH: export NODE_OPTIONS=--max_old_space_size=4096

const routes = [];
// Import new routes here
const routeComps = [AppRoutes, AuthRoutes, SandboxRoutes, DefaultRoutes];
const baseURL = import.meta.env.VITE_APP_API_URL;

let global_enablementModules = null;
let global_extendSessionData = null;

const DEBUG_ROUTES = {
	namespace: "debug",
	routes: [
		{
			path: "/test-hd",
			name: "TestPage",
			component: TestPage,
		},
	],
};
const MISC_ROUTES = {
	namespace: "x",
	routes: [
		{
			path: "x",
			name: "BlankPage",
			component: BlankPage,
		},
	],
};

function addRoutes(newRoutes, globalRoutes) {
	for (const route of newRoutes.routes) {
		let routePath = route.path;
		if (newRoutes.namespace) {
			routePath = `/${newRoutes.namespace}${routePath}`;
			route.path = routePath;
		}
		globalRoutes.push(route);
	}
}

function iterateRoutes() {
	for (const comp of routeComps) {
		if (comp.routes) {
			for (let r in comp.routes) {
				let route = comp.routes[r];
				setParentRouteMeta({
					route,
					rootRoute: route,
				}); // recursive
			}
		}
		addRoutes(comp, routes);
	}
}

function setParentRouteMeta({ route, rootRoute }) {
	if (!route || !route.children) return;
	for (let i in route.children) {
		let child = route.children[i];
		// call recursively for every child
		setParentRouteMeta({ route: child, rootRoute });
		// set meta object if it is not set
		if (!child?.meta) {
			child.meta = {};
		}
		// set root and parent componentst as a reference in child route meta
		child.meta.rootRoute = rootRoute;
		child.meta.parentRoute = route;
	}
}

// get enablement module
let triedToGetEnablementModules = false;
async function getEnablementModules() {
	let enablementModules = null;
	if (!triedToGetEnablementModules) {
		triedToGetEnablementModules = true;
		// retry loading enablementModules 3 times
		const tries = 3;
		for (let i = 1; i <= tries; i++) {
			try {
				const res = await axios.get(`${baseURL}/enablement-module`);
				enablementModules = res?.data?.feat || null;
				break;
			} catch (ex) {
				console.error(ex);
			}
			await sleep(Math.pow(2, i) * 1000); // exponential backoff (retry API call)
		}
	}
	setGlobalEnablementModules(enablementModules);
	return enablementModules;
}

// update site specific permissions on expand-session API
let triedToExtendSession = false;
async function getExtendSessionData() {
	if (!sessionStorage.sid) {
		return null;
	}
	let extendSessionRes = null;
	if (!triedToExtendSession) {
		triedToExtendSession = true;
		// retry loading enablementModules 3 times
		const tries = 3;
		for (let i = 1; i <= tries; i++) {
			try {
				const res = await axios.get(
					`${baseURL}/expand-session?sid=${sessionStorage.sid}`,
				);
				extendSessionRes = res?.data || null;
				break;
			} catch (ex) {
				console.error(ex);
			}
			await sleep(Math.pow(2, i) * 1000); // exponential backoff (retry API call)
		}
	}
	setGlobalExtendSessionData(extendSessionRes);
	return extendSessionRes;
}

function checkAllEnablementModules({ to, meta, enablementModules }) {
	const meta_Module_key_map = {
		// meta_key : module_key
		needsModuleGuestSelfRegister: "canGuestSelfRegister",
		needsModuleNewExport: "canShowNewExport",
		needsModuleNewImport: "canShowNewImport",
		needsModuleObjectStorage: "canUseObjectStorage",
	};
	// got through each enablement module check
	for (let metaKey in meta_Module_key_map) {
		let moduleBackendKey = meta_Module_key_map[metaKey];
		const routeNeedsEnablementModule = Boolean(
			Boolean(to)
				? to.matched.some((record) => record.meta[metaKey])
				: meta[metaKey] || null,
		);
		const canUseModule = Boolean(
			Boolean(enablementModules) ? enablementModules[moduleBackendKey] : false,
		);
		const moduleCheckIsOk =
			!Boolean(routeNeedsEnablementModule) || Boolean(canUseModule);
		if (!moduleCheckIsOk) {
			// if any check fails return false
			return false;
		}
	}
	return true;
}

function checkSiteSpecificPermissions({ to, meta, extendSessionData }) {
	// check read-only
	const hideRouteIfReadOnly = Boolean(to)
		? to.matched.some((record) => record.meta.hideIfReadOnly)
		: meta?.hideIfReadOnly;
	// get read-only permission for active site from session
	// permission from store has priority
	let is_read_only =
		store?._modules?.root?.state?.moduleProject?.projectMetaData?.permissions
			?.read_only;
	if (is_read_only === true || is_read_only === false) {
		return !hideRouteIfReadOnly || !is_read_only; // if read only is false, then everything is ok
	}
	// if we did not read from store, try to read from initial session data when this file (router.js) was loaded
	is_read_only = extendSessionData?.user?.currentSite?.permissions?.read_only;
	if (is_read_only === true || is_read_only === false) {
		return !hideRouteIfReadOnly || !is_read_only; // if read only is false, then everything is ok
	}
	return !hideRouteIfReadOnly || false; // default - as if read only is true - restrict user
	// return true; // default - do not have any restriction
}

function checkSessionKey({ to, meta }) {
	// If session is invalidated (but allow the routes that do not need session)
	const routeNeedsSid = Boolean(to)
		? to.matched.some((record) => record.meta.needsSid)
		: meta?.needsSid;
	const sidCheckIsOk =
		!Boolean(routeNeedsSid) || Boolean(sessionStorage.sid?.length);
	return sidCheckIsOk;
}

function getGlobalEnablementModules() {
	return global_enablementModules;
}

function getGlobalExtendSessionData() {
	return global_extendSessionData;
}

function setGlobalEnablementModules(val) {
	return (global_enablementModules = val);
}

function setGlobalExtendSessionData(val) {
	return (global_extendSessionData = val);
}

function beforeEachHandler({ to, from, next }) {
	const enablementModules = getGlobalEnablementModules();
	const extendSessionData = getGlobalExtendSessionData();

	const sidCheckIsOk = checkSessionKey({ to });
	// first check session key in session storage
	if (!sidCheckIsOk) {
		next({
			name: "LoginComp",
		});
		return;
	}

	// check enablement modules
	const allEnablementModulesCheckIsOk = checkAllEnablementModules({
		to,
		enablementModules,
	});

	// check site specific permissions (like read-only permission)
	const siteSpecificChecksAreOk = checkSiteSpecificPermissions({
		to,
		extendSessionData,
	});

	if (allEnablementModulesCheckIsOk && siteSpecificChecksAreOk) {
		next();
		return;
	}
	// if some enablement module check is not ok or site specific check is not ok, see if we can redirect to some sibling route
	if (to?.meta?.parentRoute?.children) {
		// see if any siblings can be redirected to
		for (let r in to.meta.parentRoute.children) {
			const siblingRoute = to.meta.parentRoute.children[r];
			// skip if current route is equal to sibling route
			if (to.name === siblingRoute.name || from.name === siblingRoute.name) {
				continue;
			}
			// check enablement modules
			const sibling_allEnablementModulesCheckIsOk = checkAllEnablementModules({
				meta: siblingRoute.meta,
				enablementModules,
			});
			// check session key
			const sibling_sidCheckIsOk = checkSessionKey({ meta: siblingRoute.meta });
			// check site specific permissions (like read-only permission)
			const sibling_siteSpecificChecksAreOk = checkSiteSpecificPermissions({
				meta: siblingRoute.meta,
				extendSessionData,
			});
			// check if sibling route is viable for redirect
			const sibling_isRedirectReplacement =
				siblingRoute.meta?.isRedirectReplacement === true;

			if (
				sibling_isRedirectReplacement &&
				sibling_siteSpecificChecksAreOk &&
				sibling_allEnablementModulesCheckIsOk &&
				sibling_sidCheckIsOk
			) {
				next({
					name: siblingRoute.name,
				});
				break;
			}
		}
		return;
	}

	// if all checks fail, redirect to login page by default
	next({
		name: "LoginComp",
	});
	return;
}

function setUpAndExportRouter() {
	// append all main routes to routes property
	iterateRoutes();
	// append debug routes to routes property
	if (!import.meta.env.PROD) {
		addRoutes(DEBUG_ROUTES, routes);
	}
	// append misc routes to routes property
	addRoutes(MISC_ROUTES, routes);
	// create router instance
	const router = new createRouter({
		history: createWebHistory(import.meta.env.BASE_URL),
		routes,
	});
	// init enablement modules and session checks on beforeEach event
	router.beforeEach((to, from, next) => {
		// get enablementModules config
		const enablementModules = getGlobalEnablementModules();
		// get expand-session API data
		const extendSessionData = getGlobalExtendSessionData();
		if (!enablementModules || !extendSessionData) {
			// setUpAndExportRouter must not be defined as async function at the top level
			void getEnablementModules()
				.then(() => {
					void getExtendSessionData().then(() => {
						beforeEachHandler({ to, from, next });
					});
				})
				.catch(() => {
					beforeEachHandler({ to, from, next });
				});
		} else {
			beforeEachHandler({ to, from, next });
		}
	});
	// return router instance
	return router;
}

const routeRefresh = function ({ enablementModules, extendSessionData }) {
	const currentRoute = router?.currentRoute.value;

	if (!currentRoute) {
		return;
	}

	// check session key
	const sidCheckIsOk = checkSessionKey({ meta: currentRoute.meta });
	// check enablement modules
	let allEnablementModulesCheckIsOk = true;
	if (enablementModules) {
		setGlobalEnablementModules(enablementModules);
		allEnablementModulesCheckIsOk = checkAllEnablementModules({
			meta: currentRoute.meta,
			enablementModules,
		});
	}
	// check site specific permissions (like read-only permission)
	let siteSpecificChecksAreOk = true;
	if (extendSessionData) {
		setGlobalExtendSessionData(extendSessionData);
		siteSpecificChecksAreOk = checkSiteSpecificPermissions({
			meta: currentRoute.meta,
			extendSessionData,
		});
	}

	// all checks
	const allChecksAreOk =
		siteSpecificChecksAreOk && allEnablementModulesCheckIsOk && sidCheckIsOk;
	if (!allChecksAreOk) {
		// try to redirect to ProjectList component
		router.push({
			name: "ProjectList",
		});
		return;
	}
	return;
};

const router = setUpAndExportRouter();

export { router, routeRefresh };

// export default router;
