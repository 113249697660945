import api from "@/api";

export default {
	namespaced: true,
	state: {
		exportPath: [],
		importPath: "",
		isInMiddleOfDownload: false,
		isInMiddleOfBackup: false,
	},
	getters: {},
	mutations: {
		FILE_IMPORT(state, fullPath) {
			state.importPath = fullPath;
		},
		FILE_EXPORT(state, data) {
			state.exportPath = data;
		},
		CHANGE_DOWNLOAD(state, bool) {
			state.isInMiddleOfDownload = bool;
		},
		CHANGE_BACKUP(state, bool) {
			state.isInMiddleOfBackup = bool;
		},
	},
	actions: {
		fetchAllFiles(store, data) {
			return api.fetchAllFiles(data);
		},
		getExportParamList: (store, data) => api.getExportParamList(data),
		getExportDownloadFiles: (store, data) => api.getExportDownloadFiles(data),
		forceDownload(store, data) {
			return api.forceDownload(data);
		},
		fetchSelected(store, data) {
			return api.fetchSelected(data);
		},
		import(store, data) {
			return api.import(data);
		},
		importAppend(store, data) {
			return api.importAppend(data);
		},
		applyUploadedFiles(store, data) {
			return api.applyUploadedFiles(data);
		},
		addPathFileImport({ commit }, fullPath) {
			commit("FILE_IMPORT", fullPath);
		},
		addPathFileExport({ commit }, data) {
			// String or Array
			commit("FILE_EXPORT", data);
		},
		changeDownloading({ commit }, bool) {
			commit("CHANGE_DOWNLOAD", bool);
		},
		changeBackup({ commit }, bool) {
			commit("CHANGE_BACKUP", bool);
		},
		getExportNodejs: (store, data) => api.getExportNodejs(data),
	},
};
