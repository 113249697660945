import api from "@/api";
import eventBus from "@/mixins/eventBus.js";
import isDeepEqual from "lodash.isequal";
import { routeRefresh } from "@/router";

export default {
	namespaced: true,
	state: {
		// Has to be object always
		userDataObj: {},
	},
	getters: {
		userData: (state) => ({ ...state.userDataObj.user }) || {},
		hasUserData: (state) => Boolean(Object.keys(state.userDataObj).length),
		getActiveBundle: (state, getters) => getters.userData.activeBundle || null,
		getPendingBundle: (state, getters) =>
			getters.userData.pendingBundle || null,
	},
	mutations: {
		SET_USER_DATA_TO_STORE(state, payload) {
			state.userDataObj = payload;
		},
	},
	actions: {
		forgotPass: (store, data) => api.forgotPass(data),
		resetPass: (store, data) => api.resetPass(data),
		async authLogin(store, data) {
			const res = await api.authLogin(data);
			store.dispatch("saveUserToStore", res.data);
			return res;
		},
		registerApi: (store, data) => api.registerApi(data),
		validateToken: (store, data) => api.validateToken(data),
		async expandSession({ dispatch, getters, commit }, payload = {}) {
			const res = await api.expandSession(payload); // refresh session data
			const enablementModules = await dispatch("getEnablements", null, {
				root: true,
			}); // enablement modules
			const hasUserData = getters.hasUserData;
			const activeBundleId = getters.getActiveBundle?.use_id;
			await dispatch("saveUserToStore", res.data);
			if (res.data?.user?.currentSite) {
				dispatch(
					"moduleProject/mergeProjectMetaData",
					res.data.user.currentSite,
					{
						root: true,
					},
				);
			}
			if (res.data?.user?.currentSite?.is_db_active === true) {
				commit("moduleProject/STORE_IS_DB_LOADED", true, { root: true });
			}
			const newActiveBundleId = getters.getActiveBundle?.use_id;
			if (
				hasUserData &&
				newActiveBundleId &&
				activeBundleId !== newActiveBundleId
			) {
				// Reset expand session
				console.log(
					">> Active bundle changed",
					activeBundleId,
					newActiveBundleId,
				);
				eventBus.$emit("payment-process", false);
			}
			// try to refresh current route if some permissions have changed
			routeRefresh({
				extendSessionData: res?.data,
				enablementModules: enablementModules,
			});
			return res;
		},
		saveUserToStore({ state, commit }, data) {
			const isEqual = isDeepEqual(state.userDataObj, data);
			if (!isEqual) {
				const tempObj = { ...state.userDataObj };
				for (const [key, val] of Object.entries(data)) {
					if (
						val &&
						typeof val === "object" &&
						Object.prototype.hasOwnProperty.call(tempObj, key)
					) {
						// This is used to preserve already saved keys
						// For one level object
						for (const [key2, val2] of Object.entries(val)) {
							tempObj[key][key2] = val2;
						}
					} else {
						tempObj[key] = val;
					}
				}
				commit("SET_USER_DATA_TO_STORE", tempObj);
			}
		},
		clearUserData({ commit }) {
			commit("SET_USER_DATA_TO_STORE", {});
		},
	},
};
