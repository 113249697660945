import { createApp as createVueApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import { i18n } from "./i18n";

// @ts-expect-error
import Vue3Tour from "vue3-tour";
import InlineSvg from "vue-inline-svg";
import LoadScript from "vue-plugin-load-script";
import FloatingVue from "floating-vue";

// Custom comps
import TableView from "@/modules/elements/table/TableView.vue";
import InputView from "@/modules/elements/input/InputView.vue";
import RadioInput from "@/modules/elements/radio-input/RadioInput.vue";
import CheckboxInput from "@/modules/elements/checkbox-input/CheckboxInput.vue";
import SelectView from "@/modules/elements/select/SelectView.vue";
import ContentMenu from "@/modules/context-menu/ContextMenuWrap.vue";
import ContextList from "@/modules/context-list/ContextList.vue";
import TutorialWrapper from "@/modules/main/components/TutorialWrapper.vue";
import GravatarImage from "@/modules/navigation/Comps/GravatarImage.vue";
import AppMenuBar from "@/modules/navigation/Comps/AppMenuBar.vue";
import PaginationWrap from "@/modules/pagination/PaginationWrap.vue";
import UserAvatar from "@/modules/navigation/Comps/UserAvatar.vue";
import ModernModal from "@/modules/shared/modern-modal/ModernModal.vue";
import MultiselectForm from "@/modules/shared/multiselect/MultiselectForm.vue";

import "@/assets/css/globalStyles.scss";

import sharedMixins from "@/mixins";
import tableMixins from "@/mixins/table-mixin";

// Global user config
import userConfigKeys from "@/script/userConfigKeys.js";
import "vue3-tour/dist/vue3-tour.css";

const app = createVueApp(App);
app.config.globalProperties.$userConfig = userConfigKeys;

app.use(router);
app.use(store);
app.use(i18n);
app.use(Vue3Tour);
app.use(LoadScript);
app.use(FloatingVue, {
	disposeTimeout: 100,
	popperTriggers: ["hover", "click"],
});

app.component("TableView", TableView);
app.component("InputView", InputView);
app.component("RadioInput", RadioInput);
app.component("CheckboxInput", CheckboxInput);
app.component("ContextMenu", ContentMenu);
app.component("ContextList", ContextList);
app.component("SelectView", SelectView);
app.component("TutorialWrapper", TutorialWrapper);
app.component("AppMenuBar", AppMenuBar);
app.component("GravatarImage", GravatarImage);
app.component("UserAvatar", UserAvatar);
app.component("PaginationWrap", PaginationWrap);
app.component("ModernModal", ModernModal);
app.component("MultiselectForm", MultiselectForm);
app.component("InlineSvg", InlineSvg);

app.mixin(sharedMixins);
app.mixin(tableMixins);

app.mount("#app");
