<template>
	<div class="table-outer-warp">
		<div v-if="data.search && data.search.showSearch === true" class="action">
			<input-view
				:options="data.search"
				@input="onSearch"
				@enter="$emit('enter', $event)"
			/>
		</div>
		<table-form
			:data="data"
			:elStyle="elStyle"
			:tableId="tableId"
			@saveChanges="$emit('saveChanges', $event)"
			@discardChanges="$emit('discardChanges', $event)"
			@deleteRow="$emit('deleteRow', $event)"
			@getRowData="$emit('getRowData', $event)"
			@getColumnData="$emit('getColumnData', $event)"
			@sortable="$emit('sortable', $event)"
			@infiniteScroll="$emit('infiniteScroll', $event)"
			@checkboxChanged="$emit('checkboxChanged', $event)"
		/>
	</div>
</template>

<script>
import TableForm from "./components/TableForm.vue";
export default {
	components: {
		TableForm,
	},
	props: {
		data: {
			required: true,
		},
		elStyle: {
			required: false,
			default: undefined,
		},
	},
	data() {
		return {
			searchTable: "",
			delayTimer: null,
			tableId: null,
		};
	},
	watch: {
		searchTable(val) {
			clearTimeout(this.delayTimer);
			const timeout = this.data.search.delay || 1000;
			this.delayTimer = setTimeout(() => {
				// if(this.searchTable !== ''){
				this.$emit("search", this.searchTable);
				// }
			}, timeout);
		},
	},
	mounted() {
		// console.log(this.data);
		this.tableId = Math.random().toString(36).slice(2, 7);
	},
	methods: {
		onSearch(value) {
			this.searchTable = value;
		},
	},
};
</script>

<style lang="scss">
.table-outer-warp {
	display: flex;
	justify-content: center;

	.action {
		margin-bottom: 15px;
	}
}
</style>
