<template lang="pug">
#app(:class="getAppClass", @click.self="onAppParentClick")
	.view(:class="{ 'disabled-comp': isMainInBack }")
		router-view
	modern-modal(
		v-if="selectedModernModalData.comp",
		v-bind="selectedModernModalData.binds"
	)
		template(#content)
			component(
				:is="selectedModernModalData.comp",
				v-bind="selectedModernModalData.binds"
			)
	popup-view(v-if="popupShow")
	context-menu(
		v-if="contextMenuOptions.data",
		:items="contextMenuOptions.data",
		:evt="contextMenuOptions.evt",
		@close-context-menu="closeContextMenuStorage"
	)
	spinner-block(v-if="isSpinnerBlockActive")
	mars-browser-detect
	reload-prompt
	teleport(to="#toasts")
		toast-view
</template>

<script>
import PopupView from "./modules/shared/popup/PopupView.vue";
import ToastView from "./modules/shared/toast/ToastView.vue";
import SpinnerBlock from "@/modules/shared/spinner-block/SpinnerBlock.vue";
import MarsBrowserDetect from "@/modules/shared/browser-detect/MarsBrowserDetect.vue";
import ReloadPrompt from "@/modules/shared/reload-pwa/ReloadPrompt.vue";
import modernModalComps from "./script/modernModalComps.js";
import { getLocalStorageReac } from "./assets/js/helpers";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
	mixins: [modernModalComps],
	data() {
		return {
			isBlockLiveClass: false,
		};
	},
	computed: {
		...mapState({
			selectedModernModalData: (state) => state.selectedModernModalData,
			popupOptions: (state) => state.modulePopup.popupOptions,
			contextMenuOptions: (state) => state.moduleContext.contextOptions,
			isSpinnerBlockActive: (state) => state.moduleMain.isSpinnerBlockActive,
			projectMetaData: (state) => state.moduleProject.projectMetaData,
		}),
		...mapGetters({
			getAllDirtyFilePaths: "moduleEditor/getAllDirtyFilePaths",
			isSiteReadOnly: "moduleProject/isSiteReadOnly",
		}),
		popupShow() {
			return this.popupOptions?.show;
		},
		isMainInBack() {
			return this.popupShow;
		},
		isAnyFileDirty() {
			return Boolean(this.getAllDirtyFilePaths.length);
		},
		getAppClass() {
			const isLiveTest = sessionStorage.__env === "live";
			const isLiveClassEnabled = isLiveTest && !this.isBlockLiveClass;
			return {
				liveTest: isLiveClassEnabled,
			};
		},
	},
	// watch: {
	//   $route(val, oldVal) {
	//     if (val.name === "LoginComp" && oldVal.name !== "LoginComp") {
	//       this.$router.replace({
	//         query: {
	//           ...oldVal.query,
	//           l: oldVal.path,
	//         },
	//       });
	//     }
	//   },
	// },
	mounted() {
		this.getEnablements();
		window.onbeforeunload = this.unloadAppFunc;

		this.checkIfMobileView();
		window.addEventListener("resize", this.checkIfMobileView);

		this.activateHotjarTracking();
	},
	unmounted() {
		window.removeEventListener("resize", this.checkIfMobileView);
	},
	methods: {
		...mapActions({
			setMobileView: "setMobileView",
			getEnablements: "getEnablements",
		}),
		activateHotjarTracking() {
			if (import.meta.env.PROD) {
				(function (h, o, t, j, a, r) {
					h.hj =
						h.hj ||
						function () {
							(h.hj.q = h.hj.q || []).push(arguments);
						};
					h._hjSettings = { hjid: 2_323_803, hjsv: 6 };
					a = o.querySelectorAll("head")[0];
					r = o.createElement("script");
					r.async = 1;
					r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
					a.append(r);
				})(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
			}
		},
		checkIfMobileView() {
			const mobView = window.innerWidth <= 800;
			if (this.isMobileView !== mobView) {
				this.setMobileView(mobView);
			}
		},
		unloadAppFunc() {
			return this.isAnyFileDirty ? "File is dirty" : null;
		},
		onAppParentClick(evt) {
			this.isBlockLiveClass = true;
		},
	},
};
</script>

<script setup>
function checkSetAppDarkTheme() {
	const settingReac = getLocalStorageReac(
		"app-color-scheme",
		false,
		"auto",
		true,
	);

	watch(
		settingReac,
		(isSetting) => {
			const prefersDark =
				window.matchMedia &&
				window.matchMedia("(prefers-color-scheme: dark)").matches;
			if (isSetting === "dark" || (prefersDark && isSetting !== "light")) {
				document.documentElement.classList.add("dark");
			} else {
				document.documentElement.classList.remove("dark");
			}
		},
		{ immediate: true },
	);
}

function userBrowserSetupDetection() {
	// this.isChromeUserAgent = navigator.userAgent.toLowerCase().indexOf('chrome') > 0;
	let isChromeUserAgent = false;
	let isFirefoxUserAgent = false;
	if ("userAgentData" in navigator) {
		// Chromium [89+]
		isChromeUserAgent = navigator.userAgentData.brands
			.map((item) => item.brand)
			.includes("Chromium");
		if (!isChromeUserAgent) {
			isFirefoxUserAgent =
				navigator.userAgent.toLowerCase().indexOf("firefox") > 0;
		}
	} else {
		isFirefoxUserAgent =
			navigator.userAgent.toLowerCase().indexOf("firefox") > 0;
	}

	const isSafariUserAgent =
		/constructor/i.test(window.HTMLElement) ||
		(function (p) {
			return p.toString() === "[object SafariRemoteNotification]";
		})(
			!window.safari ||
				(window.safari !== undefined && window.safari.pushNotification),
		);
	if (isFirefoxUserAgent) {
		// [Firefox] height scroll fix
		// Has to be html element! [Overflow fix]
		document.querySelectorAll("html")[0].classList.add("firefox");
	} else if (isSafariUserAgent) {
		document.querySelectorAll("html")[0].classList.add("safari");
	} else {
		document.querySelectorAll("html")[0].classList.remove("firefox");
		document.querySelectorAll("html")[0].classList.remove("safari");
	}

	onMounted(() => {
		// Prevent Firefox's backspace from navigating back
		if (!isChromeUserAgent) {
			window.addEventListener("keydown", setupFirefoxBackspace);
		}
	});
}

function setupFirefoxBackspace(evt) {
	const targetEl = evt.target || evt.srcElement;
	if (
		evt.code === "Backspace" &&
		!targetEl.matches('input,[contenteditable="true"],textarea')
	) {
		evt.preventDefault();
	}
}

userBrowserSetupDetection();

onBeforeMount(() => {
	checkSetAppDarkTheme();
});

onUnmounted(() => {
	window.removeEventListener("keydown", setupFirefoxBackspace);
});
</script>

<style lang="scss">
@import "./assets/css/base.css";
@import "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap";
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap";

html.firefox {
	overflow: hidden;
}

* {
	// Don't change these values
	scrollbar-width: thin;
	// scrollbar-width: auto;

	scrollbar-color: $light-border #343a40;
	outline-color: $light-border;
	outline-width: 1.5px;

	&::-webkit-scrollbar {
		width: 3px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		// background: $light-scrollbar;
		background: #6d6e70;
	}

	&:hover {
		scrollbar-width: thin;
		// scrollbar-width: auto;

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}
	}
}

#app {
	// max-height: 100vh; // Not well supported in mobile browsers
	// width: 100vw;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: hidden;
	background: $dark-gray;
	display: flex;
	width: 100%;

	&.liveTest {
		border: 4px solid yellow;
	}

	.view {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}
}

button,
input,
pre,
textarea {
	font-family: "Source Sans Pro", sans-serif !important;
}

.disabled-comp {
	pointer-events: none;
}
</style>
