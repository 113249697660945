<script setup lang="ts">
import { transl } from "@/i18n";
import { router } from "@/router";
import store from "@/store";

const projectName = ref("");
const getProjectInfo = computed(() => {
	return store.getters["moduleProject/getProjectInfo"];
});
const isValidName = computed(() => {
	return (
		projectName.value && projectName.value === getProjectInfo.value.pro_name
	);
});
const projectNamePlaceholder = computed(() => {
	return `Type "${getProjectInfo.value.pro_name}" here`;
	// return `${$i18n.t("settings.projectName")} (Case sensitive)`;
});

async function onDeleteProject() {
	if (!isValidName.value) {
		return;
	}

	await removeProjectApi(getProjectInfo.value);

	// const props = {
	// 	data: {
	// 		type: "confirm",
	// 		header: "Delete",
	// 		text: "Are you sure you want to delete this project?",
	// 		leftBtn: "Cancel",
	// 		rightBtn: "Confirm",
	// 		allowWrapClose: true,
	// 	},
	// 	events: {
	// 		onconfirm: async () => {
	// 			await removeProjectApi(getProjectInfo.value);
	// 		},
	// 	},
	// };
	// this.openPopupStorage(props);
}
async function removeProjectApi(payload: any) {
	const params = {
		id: payload.pro_id,
		payload: {
			pro_name: projectName.value,
		},
	};
	try {
		const res = await store.dispatch("moduleProject/deleteProject", params);
		console.log("Project deleted", res.data);
		routeToProjects();
	} catch (err: any) {
		console.warn(err.message);
		const errMsg = err?.response?.data?.message || "Unexpected error";
		const toastParams = {
			data: {
				type: "error",
				text: errMsg,
			},
		};
		store.dispatch("moduleToast/openToastStorage", toastParams);
	}
}
async function routeToProjects() {
	// const routeComp = this.$route.name;
	// if (routeComp !== "ProjectList") {
	sessionStorage.removeItem("lastProjectSiteID");
	sessionStorage.removeItem("lastProjectID");
	await store.dispatch("moduleProject/clearProjectData");
	router.replace({
		name: "ProjectList",
	});
	// }
}
</script>

<template lang="pug">
.project-delete
	.intro
		.title
			span This will delete your whole project, including all environments. This action is
			span(style="color: red") irreversible.
	hr
	.form
		.input-group
			label.label
				span Please enter your project name to confirm (case sensitive)
			input.input.flat.flat-alt(
				v-model.trim="projectName",
				type="text",
				:placeholder="projectNamePlaceholder",
				@keyup.enter="onDeleteProject"
			)
		button.btn-trans.del-project(
			:disabled="!isValidName",
			@click="onDeleteProject"
		)
			span {{ transl("settings.modalTitles.deleteProject") }}
</template>

<style lang="scss" scoped>
.project-delete {
	display: flex;
	flex-direction: column;
	background: $table-back;
	height: 100%;
	padding: 25px;
	align-items: center;
	gap: 20px;

	.intro {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 20px;
		user-select: none;

		.image {
			img {
				width: 100%;
			}
		}

		.title {
			// Flex is not good for wrapping text
			display: inline-block;

			span:last-child {
				margin-left: 5px;
			}
		}
	}

	hr {
		width: 90%;
		border-color: #3d3e3f;
		border-top: unset;
	}

	.form {
		display: flex;
		align-items: flex-end;
		gap: 10px 35px;

		.input-group {
			display: flex;
			flex-direction: column;
			gap: 10px;
			width: 100%;
		}

		input {
			height: 55px;
		}

		button.del-project {
			$back: #f83866;

			height: 55px;
			background: $back;
			color: $alt-background;
			font-weight: 600;

			&:disabled {
				background: darken($color: $back, $amount: 10);
			}
		}
	}

	@media screen and (min-width: 768px) {
		.form {
			.input-group {
				width: 365px;
			}
		}
	}
}
</style>
