import store from "@/store";

/**
 * @param {any} payload
 * @returns {Promise<boolean>}
 */
export async function autoEnterSiteFunc(payload) {
	/**
	 * @param {string} [message]
	 */
	const openWarningMsg = (message) => {
		const params = {
			data: {
				type: "warning",
				text: message ?? "No site found",
			},
		};
		store.dispatch("moduleToast/openToastStorage", params);
	};

	try {
		// Set project info before entering
		const resProject = await store.dispatch(
			"moduleProject/getProjectData",
			payload.project,
		);
		const projectInfo = resProject.data.data;
		await store.dispatch("moduleProject/setProjectData", projectInfo);

		const responseBool = await store.dispatch(
			"moduleProject/autoEnterSite",
			payload,
		);
		if (responseBool) {
			return true;
		}
		openWarningMsg();
	} catch (err) {
		console.warn(err.message);
		sessionStorage.removeItem("lastProjectSiteID");
		sessionStorage.removeItem("lastProjectID");
		openWarningMsg(err.response?.data?.message);
	}
	return false;
}

export async function loadSiteDataFromSessionStorage() {
	let result = false;
	// if site and project are missing, try to load them once again
	let site = sessionStorage.lastProjectSiteID;
	let project = sessionStorage.lastProjectID;
	// try to load site and project data
	if (site && project) {
		await store.dispatch("moduleMain/setLoading", true);
		result = await autoEnterSiteFunc({site, project});
	}
	await store.dispatch("moduleMain/setLoading", false);
	return result;
}

/**
 * @param {{ name: string; }} to
 * @param {{}} from
 * @param {any} nextCallback
 */
export async function routeFirstPageProject(to, from, nextCallback) {
	let siteDataLoaded = await loadSiteDataFromSessionStorage();
	if (siteDataLoaded) {
		nextCallback();
	} else {
		// if site data was not loaded, redirect to projects list page
		nextCallback({ name: "ProjectList" });
	}
}
