<template>
	<div class="modal-wrapper">
		<!-- <div class="modal-title">
      Add new domain
    </div> -->
		<div class="section">
			<span class="title">Domain name</span>
			<input v-model="domainName" type="text" @keyup.enter="addDomain" />
		</div>
		<div class="section actions">
			<button class="btn cancel inv" @click="closeModernModalCompat">
				Cancel
			</button>
			<button class="btn apply" @click="addDomain">Add</button>
		</div>
	</div>
</template>

<script>
import api from "@/api";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
	name: "AddDomain",
	data() {
		return {
			domainName: "",
		};
	},
	methods: {
		...mapActions({
			appendDomainAutoSiteOpen: "appendDomainAutoSiteOpen",
			appendCurrentSiteDomain: "moduleProject/appendCurrentSiteDomain",
		}),
		addDomain() {
			const params = {
				sit_id: this.getSiteData.sit_id,
				dom_name: this.domainName,
			};
			api
				.createDomain(params)
				.then((res) => {
					if (res.data.result === "OK") {
						// refresh list of available domains
						const updateDom = {
							dom_id: res.data.id,
							dom_name: params.dom_name,
							is_https: 0,
						};
						void this.appendDomainAutoSiteOpen(updateDom);
						void this.appendCurrentSiteDomain(updateDom);
						this.openToastSuccessMsg("Domain added successfully");
					} else {
						if (res.data.message) {
							this.openToastErrorMsg(res.data.message);
						} else {
							this.openToastErrorMsg("Error adding domain");
						}
					}
					this.closeModernModalCompat();
				})
				.catch((e) => {
					if (e.response.data.message) {
						this.openToastErrorMsg(e.response.data.message);
					} else {
						this.openToastErrorMsg("Error adding domain");
					}
					this.closeModernModalCompat();
				});
		},
	},
	computed: {
		...mapGetters({
			getSiteData: "moduleProject/getSiteData",
		}),
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/adminModals";
.modal-wrapper {
	@include admin-modals-styles;
}
</style>
