import axios from "axios";
import { router } from "../router";
import { i18n } from "@/i18n.ts";
import store from "@/store";
import { infoConsole } from "@/assets/js/helpers";

let baseURL = import.meta.env.VITE_APP_API_URL;

if (sessionStorage.__env === "live") {
	// Hardcoded values for testing
	baseURL = import.meta.env.VITE_APP_API_URL_LIVE;
}

const instance = axios.create({
	baseURL,
});

instance.interceptors.request.use((config) => {
	handleRequestInterceptor(config);

	return config;
});

instance.interceptors.response.use(
	(response) => {
		handleResponseInterceptor(response);

		return response;
	},
	(error) => {
		handleResponseErrorInterceptor(error);

		return Promise.reject(error);
	},
);

const apiCheckSession = () => Boolean(sessionStorage.sid);

// When the login modal is open [in app]
let loginModalLoaded = false;
let wasInApp = false;
const PROJECT_COMP_NAME = "ProjectList";
const LOGIN_COMP_NAME = "LoginComp";
const RESPONSE_STATUS_CODE = {
	unauthorized: 401,
	forbidden: 403,
};

const getCurrentRoute = () => {
	return router.currentRoute.value;
};

/**
 * @param {{ response: { status?: any; data: { message: string; result: string; }; }; }} error
 */
async function handleResponseErrorInterceptor(error) {
	const isValidSes = apiCheckSession();
	const statusNum = error.response?.status;
	if (loginModalLoaded) {
		showModalOpenMsg();
	} else {
		if (
			isValidSes === false ||
			statusNum === RESPONSE_STATUS_CODE.unauthorized
		) {
			const isNotLoggedMsg = checkLoggedInResponseMsg(error.response);
			if (
				wasInApp &&
				statusNum === RESPONSE_STATUS_CODE.unauthorized &&
				isNotLoggedMsg
			) {
				// LoginPopup in error response 401 [Not Logged in]
				await invokePopupLogin();
			} else {
				if (getCurrentRoute().name !== "RegisterComp") {
					infoConsole(
						"🐛 handleResponseErrorInterceptor",
						"log",
						getCurrentRoute(),
					);
					removeResetSession();
				}
			}
			infoConsole("xhr config", "warn", error.response);
		} else if (wasInApp && statusNum === RESPONSE_STATUS_CODE.forbidden) {
			// Don't invoke login if forbidden
			// invokePopupLogin()
		}
	}
}

/**
 * @param {import("axios").AxiosRequestConfig} config
 */
function handleRequestInterceptor(config) {
	try {
		const sid = sessionStorage.sid;
		if (config.params) {
			config.params.sid = sid;
		} else {
			config.params = {
				sid,
			};
		}
	} catch (err) {
		infoConsole("eRRoR", "log", err);
	}
}

/**
 * @param {import("axios").AxiosResponse<any>} response
 */
async function handleResponseInterceptor(response) {
	const isNotLoggedMsg = checkLoggedInResponseMsg(response);
	if (isNotLoggedMsg) {
		if (wasInApp) {
			if (loginModalLoaded) {
				showModalOpenMsg();
			} else {
				await invokePopupLogin();
			}
		} else {
			removeResetSession();
		}
	} else {
		// Check for other error messages
		if (loginModalLoaded) {
			showModalOpenMsg();
		} else {
			parseResponseMessages(response);
		}
		wasInApp = true;
	}
}

/**
 * @param {{ data: { message: string; result: string; }; }} res
 */
function parseResponseMessages(res) {
	if (res.data?.result === "ERR" && res.data?.message) {
		const msgs = ["Database not selected"];
		const projectLink = "/projects";
		if (msgs.includes(res.data.message) && location.pathname !== projectLink) {
			router.push({
				name: PROJECT_COMP_NAME,
				params: {
					// @ts-ignore
					reset: true, // This will remove selected project info
				},
			});
		}
	}
}

/**
 * @param {{ data: { message: string; result: string; }; }} res
 */
function checkLoggedInResponseMsg(res) {
	// This is used when reponse is [200 - OK]
	if (res.data?.result === "ERR" && res.data?.message) {
		const msgs = ["Not logged in"];
		// Database not selected in transfer api logs out
		if (msgs.includes(res.data.message)) {
			return true;
		}
	}

	return false;
}

async function invokePopupLogin() {
	const checkAndShowPopup = async () => {
		if (getCurrentRoute().name !== LOGIN_COMP_NAME) {
			loginModalLoaded = true;
			sessionStorage._isAuthPopupOpen = true;
			try {
				await new Promise((resolve, reject) => {
					callAuthPopup(resolve, reject);
				});
				loginModalLoaded = false;
				sessionStorage._isAuthPopupOpen = false;
			} catch (err) {
				infoConsole(err.message, "warn");
				loginModalLoaded = false;
				sessionStorage._isAuthPopupOpen = false;
			}
		}
	};

	setTimeout(async () => {
		// Added delay because popup was showing in the login component (page)
		await checkAndShowPopup();
	}, 300);
}

function removeResetSession() {
	if (getCurrentRoute().name === LOGIN_COMP_NAME) {
		infoConsole("Comp was login");
	} else {
		infoConsole("Removing session");
		sessionStorage.clear();
		router.push({
			name: LOGIN_COMP_NAME,
			query: {
				__frcon: true, // __fromConfig
			},
		});
	}
}

function showModalOpenMsg(msg = ">> LoginPopup already open") {
	infoConsole(msg);
}

function callAuthPopup(resolve, reject) {
	infoConsole("> Call Auth Popup");
	const modalProps = {
		data: {
			comp: "AuthPopup",
			allowClose: false,
			style: {
				width: "500px",
				height: "450px",
			},
			options: {
				header: i18n.global.t("auth.login"),
			},
		},
		events: {
			onclose: () => {
				resolve();
			},
		},
	};
	store.dispatch("openModernModalCompat", modalProps);
}

export { instance, baseURL };
